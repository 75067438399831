import { DEFAULT_BASE_URL, DEFAULT_LOG_URL } from "../../api-sdk/constants";
import { SetupError } from "../../utils/beam-errors";
import { BeamStatusChangeEvent } from "../../utils/events";
import { logger } from "../../utils/logger";
import { TApiKey, TNumericId, TUrl } from "../../shared/types";
import { sendLog } from "../logs";

export type BeamConfigOptions = {
  apiKey: TApiKey;
  domain?: TUrl;
  chainId: TNumericId;
  storeId?: TNumericId;
  baseUrl?: TUrl;
  logUrl?: TUrl;
  plugins?: BeamPlugin[];
};

export type BeamPlugin = {
  name: string;
  init: (config: BeamConfigOptions) => Promise<void>;
};

// Set up a promise outside the beam config singleton so that we can keep a stable reference to the same promise
// if anyone uses getConfig() before init() has been called with final configuration
let resolveBeamReadyPromise: (result: boolean) => any;
let rejectBeamReadyPromise: (reason: SetupError) => any;
const beamReadyPromiseSingleton = new Promise<boolean>((resolve, reject) => {
  resolveBeamReadyPromise = resolve;
  rejectBeamReadyPromise = reject;
});

/**
 * BeamConfig is not exported, it should be accessed as a singleton via getConfig & init
 * @example
 * import { init, getConfig } from '@beamimpact/web-sdk/dist/integrations/beam'
 * // In setup script
 * const beam = await init({ apiKey: '', chainId: 1, storeId: 1, plugins: [] })
 * // In other scripts that need to wait for Beam to be ready
 * const beam = getConfig()
 * await beam.readyPromise.then(() => doSomething())
 * // OR
 * if (beam.status !== 'ready') {
 *   beam.addEventListener('beamstatuschange', event => {
 *     if (event.detail.status === 'ready') doSomething()
 *     else if (event.detail.status === 'error') handleBeamError()
 *   })
 * }
 */
class BeamConfig extends EventTarget {
  apiKey?: TApiKey;

  chainId?: TNumericId;

  storeId?: TNumericId;

  /** Domain to set Beam cookies on, used if store and checkout are on different subdomains */
  domain?: TUrl;

  /** Beam server URL to make API requests */
  baseUrl?: TUrl;

  /** Beam server URL for logging / errors */
  logUrl?: TUrl;

  /** Alternative to adding event listener for beamstatuschange - resolves when Beam and all plugins are ready */
  readyPromise: Promise<boolean> = beamReadyPromiseSingleton;

  /** getConfig().addEventListener("beamstatuschange", ({detail}) => { console.log(detail.status) }) */
  status: "pending" | "ready" | "error";

  /** Plugins such as Statsig for A/B tests - Beam waits for all plugins to initialize before emitting ready status */
  plugins: { [pluginName: string]: BeamPlugin } = {};

  /** Sets up Beam in pending state. This is used for initial value of Beam config singleton,
   * which allows scripts to access and wait for "ready" promise or event before initialization script runs.
   * Use init() to set up Beam for use. */
  constructor(options: Partial<BeamConfigOptions>) {
    super();
    this.status = "pending";
    this.#initFields(options);
  }

  /** Used by end users to set up Beam with config options and plugins. */
  async init(options: BeamConfigOptions): Promise<BeamConfig> {
    // Re-apply constructor logic with new options
    this.#initFields(options);
    try {
      await this.#initPlugins(options);
    } catch (err: any) {
      const error = new SetupError();
      error.cause = err;
      this.status = "error";
      this.dispatchEvent(new BeamStatusChangeEvent({ status: "error", error }));
      rejectBeamReadyPromise(error);
      logger.error(error);
      if (this.apiKey) {
        await sendLog(
          { apiKey: this.apiKey, baseUrl: this.logUrl },
          { type: "error", code: error.name, metadata: { message: error.message, cause: err?.message } }
        );
      }
      throw err;
    }
    this.status = "ready";
    this.dispatchEvent(new BeamStatusChangeEvent({ status: "ready" }));
    resolveBeamReadyPromise(true);
    return this;
  }

  #initFields(options: Partial<BeamConfigOptions>) {
    this.apiKey = options.apiKey;
    this.chainId = options.chainId;
    this.storeId = options.storeId;
    this.domain = options.domain;
    this.baseUrl = options.baseUrl || DEFAULT_BASE_URL;
    this.logUrl = options.logUrl || DEFAULT_LOG_URL;
  }

  async #initPlugins(config: BeamConfigOptions) {
    const pluginInitPromises = (config.plugins || []).map(async (plugin) => {
      await plugin.init(config);
      this.plugins[plugin.name] = plugin;
      return plugin;
    });
    return Promise.all(pluginInitPromises);
  }
}

/** We only want one instance of config throughout SDK so anyone can wait for initialization async using this
 *  reference */
const beamConfigSingleton: BeamConfig = new BeamConfig({});

/**
 * @example
 * import { init, getConfig } from '@beamimpact/web-sdk/dist/integrations/beam'
 *
 * const beam = await init({
 *   apiKey: '',
 *   chainId: 1,
 *   storeId: 1,
 *   plugins: [ new StatsigPlugin({ statsigApiKey: '' }) ]
 * })
 *
 * beam.plugins.statsig.logEvent('cart_updated', 100.00)
 */
export const init = (beamConfigOptions: BeamConfigOptions): Promise<BeamConfig> => {
  return beamConfigSingleton.init(beamConfigOptions);
};

/**
 * @example
 * // In scripts that need to wait for Beam to be ready
 * const beam = getConfig()
 * await beam.readyPromise
 *   .then(() => doSomething(beam))
 *   .catch(err => handleBeamError(err))
 * // OR
 * if (beam.status !== 'ready') {
 *   beam.addEventListener('beamstatuschange', event => {
 *     if (event.detail.status === 'ready') doSomething(beam)
 *     else if (event.detail.status === 'error') handleBeamError(event.detail.error)
 *   })
 * }
 */
export const getConfig = (): BeamConfig => beamConfigSingleton;
